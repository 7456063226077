import React, { useEffect } from "react";
import { useForm } from 'react-hook-form';
import { graphql, Link, navigate } from "gatsby";
import BackgroundImage from 'gatsby-background-image';

import Aos from 'aos';
import 'aos/dist/aos.css';
import indexVideomp4 from '../video/placeholder_1.mp4';

import Layout from "../components/layout/layout";
import { Helmet } from 'react-helmet';


const IndexPage = ({ data }) => {
  const { indexImage1, indexImage2, indexImage3, indexImage4, indexImage5, indexImage6 } = data;
  const { allContentfulNewsrelease: { nodes: newsrelease } } = data;
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    navigate('/contact/', { state: { data } });
  };

  let AOS;
  useEffect(() => {
    const AOS = require("aos");
    AOS.init({ once: true, duration: 500 });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

  return (
    <Layout location="home" metadata={ { title: "株式会社テストサービス" } }>
      <main className="page page--homepage">
      <section className="section-landing section-background-video--landing section-background-video section">
          <div className="section-background-video__background-video">
            <video className="section-background-video__background-video-content" autoPlay muted loop>
              <source src={ indexVideomp4 } type="video/mp4" />
                { /* <source src="" type="video/webm" /> */ }
              <img src={ indexImage4.childImageSharp.fluid } title="Video content not supported" />
            </video>
            <div className="section-landing__text-box">
              <h1 className="section-landing__header">
                <span className="section-landing__header--main"> テストサービス</span>
                <span className="section-landing__header--sub">人と社会と地球の未来のために</span>
              </h1>
            </div>
          </div>
        </section>
        <section className="section-tagline section">
          <h2 className="section-tagline__header section__subheader content-subheader">
            徹底した品質管理が私たちのモットーです。
          </h2>
          <p className="section-tagline__leading section__leading content-leading">
            for the Future of Us, the Society and the Earth
          </p>
        </section>
        <section className="section-welcome section">
          <h2 className="section-wecome__header section__subheader content-subheader">Welcome</h2>
          <p className="section-welcome__leading section__leading content-leading">
            革新的なサービスと揺るぎない技術力を提供します。 それぞれ一人一人の考える力、
            創造力、実行力が私達を向上をさせてくれます。 活躍してくれている社員の舞台を整えたい、 それが当社における 人材マネジメントの考え方です。 
            私達の原動力が新しい価値を創造していく。 
            プロフェッショナルな知識・能力、多様な価値観そういった融合が今、当社の中で変わろうとしています。
          </p>
        </section>
        <section className="section-cards section">
          <div className="section-cards__flex-container">
            <div className="section-cards__flex-item">
              <Link to="/system/">
                <BackgroundImage className="background" fluid={ indexImage1.childImageSharp.fluid } critical>
                  <div className="background-overlay background-overlay--dark">
                    <div className="section-cards__text-box">
                      <h2 className="section-cards__header" data-aos="fade" data-aos-once="false">業務・設備</h2>
                      <Link className="section-cards__link" to="/system/" data-aos="fade" data-aos-once="false">Read More</Link>
                    </div>
                  </div>
                </BackgroundImage>
              </Link>
            </div>
            <div className="section-cards__flex-item">
              <Link to="/members/">
                <BackgroundImage className="background" fluid={ indexImage3.childImageSharp.fluid } critical>
                  <div className="background-overlay background-overlay--dark">
                    <div className="section-cards__text-box">
                      <h2 className="section-cards__header" data-aos="fade" data-aos-once="false">会員専用</h2>
                      <Link className="section-cards__link" to="/members/" data-aos="fade" data-aos-once="false">Read More</Link>
                    </div>
                  </div>
                </BackgroundImage>
              </Link>
            </div>
            <div className="section-cards__flex-item">
              <Link to="/recruit/">
                <BackgroundImage className="background" fluid={ indexImage2.childImageSharp.fluid } critical>
                  <div className="background-overlay background-overlay--dark-red">
                    <div className="section-cards__text-box">
                      <h2 className="section-cards__header" data-aos="fade" data-aos-once="false">採用情報</h2>
                      <Link className="section-cards__link" to="/recruit/" data-aos="fade" data-aos-once="false">Read More</Link>
                    </div>
                  </div>
                </BackgroundImage>
              </Link>
            </div>
          </div>
        </section>
        <section className="section-company-preview section-background-video--opacity section-background-video section">
          <div className="section-background-video__background-video">
            <video className="section-background-video__background-video-content" autoPlay muted loop>
              <source src={ indexVideomp4 } type="video/mp4" />
                { /* <source src="" type="video/webm" /> */ }
              <img src={ indexImage4.childImageSharp.fluid } title="Video content not supported" />
            </video>
            <div className="section-company-preview__text-box">
              <h2 className="section-company-preview__header section__subheader content-subheader" data-aos="fade">ニュースリリース</h2>
              <p className="section-company-preview__leading section__leading content-leading" data-aos="fade">
                ニュースリリース　☆ { newsrelease.map(news => <p>{ news.text.text }</p>) }
              </p>
              <h2 className="section-company-preview__header section__subheader content-subheader u-margin-top-xlarge" data-aos="fade">会社案内</h2>
              <p className="section-company-preview__leading section__leading content-leading u-margin-bottom-xlarge" data-aos="fade">
                働く”を通して人々に笑顔を
              </p>
              <Link className="section-company-preview__link u-margin-bottom-medium" to="/company/">Read more</Link>
            </div>
          </div>
        </section>
        <section className="section-contact-preview section">
          <div className="section-contact-preview__flex-container">
            <form className="section-contact-preview__form" onSubmit={ handleSubmit(onSubmit) }>
              <h2 className="section-contact-preview__header u-margin-bottom-small">お問合わせ</h2>
              <h3 className="section-contact-preview__subheader">兼 苦情受付窓口</h3>
              <div className="section-contact-preview__form-group">
                <input 
                  className={ errors.company ? 'section-contact-preview__form-input error' : 'section-contact-preview__form-input' }
                  type="text" 
                  placeholder="会社名" 
                  name="company" 
                  ref={ register({ required: true }) } 
                  required />
                <label className="section-contact-preview__form-label" for="company">会社名</label>
              </div>
              <div className="section-contact-preview__form-group">
                  <input 
                    className={ errors.name ? 'section-contact-preview__form-input error' : 'section-contact-preview__form-input' }
                    type="text" 
                    placeholder="名前" 
                    name="name"
                    ref={ register({ required: true }) } 
                    required />
                  <label className="section-contact-preview__form-label" for="name">名前</label>
              </div>
              <div className="section-contact-preview__form-group">
                  <input 
                    className={ errors.email ? 'section-contact-preview__form-input error' : 'section-contact-preview__form-input' }
                    type="email" 
                    placeholder="メールアドレス" 
                    name="email"
                    ref={ register({ required: true }) } 
                    required />
                  <label className="section-contact-preview__form-label" for="email">メールアドレス</label>
              </div>
              <div className="section-contact-preview__form-group">
                  <input 
                    className={ errors.phone ? 'section-contact-preview__form-input error' : 'section-contact-preview__form-input' }
                    type="phone" 
                    placeholder="電話番号" 
                    name="phone"
                    ref={ register } 
                    required />
                  <label className="section-contact-preview__form-label" for="phone">電話番号</label>
              </div>
              <div className="section-contact-preview__form-group section-contact-preview__form-group--submit-area">
                  <input className="button button--primary button--enlarged" type="submit" value="お問い合わせ" />
              </div>
            </form>
            <div className="section-contact-preview__gmap-canvas">
                <iframe 
                    className="section-contact-preview__iframe"
                    src="https://maps.google.com/maps?q=%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%9D%BF%E6%A9%8B%E5%8C%BA%E5%A4%A7%E5%B1%B1%E9%87%91%E4%BA%95%E7%94%BA47-12&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=&amp;output=embed" 
                    scrolling="no" 
                    hl="jl"
                /> 
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
{
  allContentfulNewsrelease {
      nodes {
          text {
            text
          }
      }
  }
  indexImage1: file(relativePath: {eq: "stock/placeholder.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  indexImage2: file(relativePath: {eq: "stock/placeholder_2.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  indexImage3: file(relativePath: {eq: "stock/placeholder_3.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  indexImage4: file(relativePath: {eq: "stock/placeholder_7.jpeg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  indexImage5: file(relativePath: {eq: "stock/placeholder_9.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  indexImage6: file(relativePath: {eq: "stock/recruit05.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
}
`;

export default IndexPage;
